import request from 'graphql-request';

import { SetFeedbackDocument } from '../gql/graphql';
import { ACCESS_KEY, ENGINE_API } from './shared';

export const setFeedback = async (opt: {
  orderNo: string;
  text: string;
  score: number;
  ref: string;
}) => {
  return await request({
    url: ENGINE_API,
    document: SetFeedbackDocument,
    variables: {
      orderNo: opt.orderNo,
      text: opt.text,
      score: opt.score,
      ref: opt.ref,
    },
    requestHeaders: {
      'x-api-key': ACCESS_KEY,
    },
  });
};
